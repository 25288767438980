import configs from "configs";
import { lazy } from "react";

const AdminPage = lazy(() => import('pages/private/admin'))

const privateRoutes = [
    {
        path: configs.path.APP_PREFIX + configs.path.ADMIN_PREFIX,
        element: <AdminPage />
    }
]

export default privateRoutes;