const path = {
    PUBLIC_PREFIX: '/',
    ALL: '/all',
    PRIVATE_PREFIX: '/private',
    APP_PREFIX: '/app/',
    PRODUCT_PREFIX: '/product',
    PRODUCT_PREFIX_WITH_QUERY: '/product/:id',
    LOGIN_PREFIX: "/login",
    SIGNUP_PREFIX: "/register",
    AUTH_PREFIX : '/auth',
    ADMIN_PREFIX: '/admin',
    SEARCH_BY: {
        TAGS: '/tags'
    }
}

export default path;