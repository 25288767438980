import configs from "configs";
import { createBrowserRouter } from "react-router-dom";
import publicRoutes from "./public.route";
import PublicPages from "pages/public";
import PrivatePages from "pages/private";
import privateRoutes from "./private.route";

const mainRouter = createBrowserRouter([
    {
        path: configs.path.PUBLIC_PREFIX,
        element: <PublicPages />,
        children: publicRoutes
    },
    {
        path: configs.path.APP_PREFIX,
        element: <PrivatePages />,
        children: privateRoutes
    }
])

export default mainRouter;