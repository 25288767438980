import { RouterProvider } from 'react-router-dom';
import mainRouter from './routes';
import GlobalStyle from 'style/globalStyle';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { authSlice } from 'reducers';
import { LocalStorage } from 'helper';

import './App.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  const dispatch = useDispatch()

  const authData = LocalStorage.get('auth')

  if (authData?.token) {
    dispatch(authSlice.actions.login(authData))
  }
  else {
    dispatch(authSlice.actions.logout)
    LocalStorage.clear()
  }

  return (
    <>
      <GlobalStyle />
      <RouterProvider router={mainRouter} />
      <Toaster />
    </>
  );
}

export default App;
