import Flex from "components/basic/flex";
import Link from "components/basic/link";
import Button from "../button";
import configs from "configs";

const AuthButtonGroup = () => {
    return (
        <Flex>
            <Link to={configs.path.SIGNUP_PREFIX}>
                <Button $style={{
                    color: "white"
                }}>Sign up</Button>
            </Link>
            <Link to={configs.path.LOGIN_PREFIX}>
                <Button $style={{
                    bg: "white"
                }}>Log in</Button>
            </Link>
        </Flex>
    )
}

export default AuthButtonGroup