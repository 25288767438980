import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import { Input } from "../input";
import Button from "../button";
import Link from "components/basic/link";
import UserPrivateArea from "./UserPrivateArea";
import configs from "configs";

export default function Header() {
    return (
        <Flex as="header" $style={{
            background: "black",
            vAlign: "center",
            hAlign: "center",
            p: "0 2rem",    
        }}>
            <Flex $style={{
                maxW: "1440px",
                w: "100vw",
                hAlign: "space-between",
                vAlign: "center"
            }}>
                <Link to={configs.path.PUBLIC_PREFIX}>
                    <Heading $style={{
                        color: "white"
                    }} level={4}>DealDiscovery</Heading>

                </Link>
                <Flex $style={{
                    vAlign: "center",
                    queries: {
                        790: {
                            display: "none"
                        }
                    }
                }}>
                    <Input $style={{
                        color: "white"
                    }} placeholder="Search Deals..." />
                    <Button $style={{
                        color: "color",
                        bg: "white"
                    }}>Search</Button>
                </Flex>
                <Flex>
                    <UserPrivateArea />
                </Flex>
            </Flex>
        </Flex>
    )
}