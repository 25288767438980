class LocalStorage {
    static set = (name: string, data: any) => {
        if(localStorage) {
            localStorage.setItem(name, JSON.stringify(data))
        }
    }

    static get = (name: string) => {
        const data = localStorage.getItem(name)
        
        if(localStorage && data) {
            console.log(data)
            return JSON.parse(data)
        }
        else {
            return null
        }
    }

    static remove = (name: string) => {
        if(localStorage) {
            localStorage.removeItem(name)
        }
    }

    static clear = () => {
        if(localStorage) {
            localStorage.clear()
        }
    }
}

export default LocalStorage