import configs from "configs";
import { lazy } from "react";

const HomePage = lazy(() => import('pages/public/home'));
const ProductPage = lazy(() => import('pages/public/product'));
const LoginPage = lazy(() => import('pages/public/login'));
const SignupPage = lazy(() => import('pages/public/signup'));

const publicRoutes = [
    {
        path: configs.path.PUBLIC_PREFIX,
        element: <HomePage />
    },
    {
        path: configs.path.PRODUCT_PREFIX_WITH_QUERY,
        element: <ProductPage />
    },
    {
        path: configs.path.LOGIN_PREFIX,
        element: <LoginPage />
    },
    {
        path: configs.path.SIGNUP_PREFIX,
        element: <SignupPage />
    }
]

export default publicRoutes;