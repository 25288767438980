import { createSlice } from "@reduxjs/toolkit";
import ACTION_TYPE from "constants/action.type";

const initialState = {
    user: null,
    isAuthenticated: false
}

const authSlice = createSlice({
    name: ACTION_TYPE.USER_AUTH,
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true
        },
        logout: (state) => {
            state.user = null
            state.isAuthenticated = false
        }
    }
})

export default authSlice