import React from 'react'
import Avatar from "../avatar";
import Flex from "components/basic/flex";
import AuthButtonGroup from "./AuthButtonGroup";
import { LocalStorage } from 'helper';

const UserPrivateArea = () => {
    const auth = LocalStorage.get('auth')
    
    return (
        <Flex>
            {
                auth?.token ? 
                <Avatar name="leonsummer" /> : 
                <AuthButtonGroup />
            }
        </Flex>
    )
}

export default UserPrivateArea