import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import Icon from "components/basic/icon";
import Link from "components/basic/link";
import List, { ListItem } from "components/basic/list";
import { P } from "components/basic/text";
import configs from "configs";

export default function Footer() {
    return (
        <Flex as="footer" $style={{
            background: "black",
            hAlign: "center",
            p: "3rem 1rem",
            mt: "3rem"
        }}>
            <Flex $style={{
                maxW: "1440px",
                w: "100vw",
                hAlign: "space-between",
                vAlign: "center",
                gap: "1rem",
                queries: {
                    620: {
                        fDirection: "column",
                        vAlign: "flex-start"
                    }
                }
            }}>

                <Flex $style={{
                    fDirection: "column",
                    maxW: "30rem",
                    gap: "1rem"
                }}>
                    <Link to={configs.path.PUBLIC_PREFIX}>
                        <Heading $style={{
                            color: "white"
                        }} level={4}>DealDiscovery</Heading>
                    </Link>
                    <P $style={{
                        size: ".8rem"
                    }}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </P>
                    <Flex $style={{
                        gap: "1rem"
                    }}>
                        <Icon icon="twitter" />
                        <Icon icon="instagram" />
                        <Icon icon="facebook" />
                        <Icon icon="linkedin" />
                    </Flex>
                </Flex>
                <List $style={{
                    dir: "column",
                    gap: "0.5rem"
                }}>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Deals</ListItem>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Online Coupons</ListItem>
                </List>
                <List $style={{
                    dir: "column",
                    gap: "0.5rem"
                }}>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Credit Cards</ListItem>
                </List>
                <List $style={{
                    dir: "column",
                    gap: "0.5rem"
                }}>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Deals</ListItem>
                </List>
                <List $style={{
                    dir: "column",
                    gap: "0.5rem"
                }}>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Navigation</ListItem>
                    <ListItem hideDot $style={{
                        color: "color"
                    }}>Contact Us</ListItem>
                </List>
            </Flex>
        </Flex>
    )
}