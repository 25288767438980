import React from "react"
import Flex from "components/basic/flex"
import { P } from "components/basic/text"

interface AvatarProps {
    name: string
}

const Avatar: React.FC<AvatarProps> = ({ name }) => {
    return (
        <Flex
            $style={{
                w: '50px',
                borderradius: '50%',
                background: 'DodgerBlue',
                vAlign :'center',
                hAlign:"center",
                cursor: "pointer"
            }}
        >
            <P 
                $style={{
                    color: '#ffffff',
                    size: '30px',
                    align: 'center'
                }}
            >
                {
                    name.slice(0, 1).toUpperCase()
                }
            </P>
        </Flex>
    )
}

export default Avatar