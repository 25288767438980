import Footer from "components/custom/footer";
import Header from "components/custom/header";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export default function PublicPages() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Header />
            <Outlet />
            <Footer />
        </Suspense>
    )
}