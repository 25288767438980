import styled from 'styled-components';

export interface InlineFlexPropsType {
	flex?: string
	fDirection?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
	fWrap?: 'wrap' | 'no-wrap';
	vAlign?: string
	hAlign?: string
	gap?: string
	count?: number
	p?: string,
	pl?: string,
	pr?: string,
	pt?: string,
	pb?: string,
	mb?: string
	mt?: string
	w?: string
	minW?: string
	maxW?: string
	h?: string
	minh?: string
	maxh?: string
	between?: string
	background?: string
	overflow?: 'auto' | 'hidden' | 'scroll'
	position?: 'absolute' | 'relative' | 'fixed' | 'sticky'
	radius?: string
	top?:string
	bottom?:string
	left?:string
	right?:string
	transform?:string
	zIndex?:string
	border?:string
	display?: 'none' | 'flex',
	borderradius?: string | number
	cursor? : 'pointer' | 'normal',
	justifycontent?: string
}

type QueryType = { [key: string]: InlineFlexPropsType };

export interface StyledFlexPropsType extends InlineFlexPropsType {
	queries?: QueryType
}

export interface StyledFlexChildPropsType {
	w?: string
	h?: string
}

const setStyle = ({
	flex,
	fDirection,
	fWrap,
	vAlign,
	hAlign,
	gap,
	p,
	pl,
	pr,
	pt,
	pb,
	mb,
	mt,
	w,
	minW,
	maxW,
	h,
	minh,
	maxh,
	background,
	position,
	overflow,
	between,
	radius,
	top,
	display,
	bottom,
	left,
	cursor,
	right,
	transform,
	zIndex,
	border,
	borderradius,
	justifycontent
}: InlineFlexPropsType) => {
	return `
		${flex ? `flex:			${flex};` : ``}
		${border ? `border:			${border};` : ``}
		${fDirection ? `flex-direction:	${fDirection};` : ``}
		${fWrap ? `flex-wrap:		${fWrap};` : ``}
		${vAlign ? `align-items:		${vAlign};` : ``}
		${hAlign ? `justify-content:	${hAlign};` : ``}
		${gap ? `gap:				${gap};` : ``}
		${p ? `padding:			${p};` : ``}
		${pl ? `padding-left:			${pl};` : ``}
		${pr ? `padding-right:			${pr};` : ``}
		${pt ? `padding-top:			${pt};` : ``}
		${pb ? `padding-bottom:			${pb};` : ``}
		${mb ? `margin-bottom:	${mb};` : ``}
		${mt ? `margin-top:	${mt};` : ``}
		${w ? `width:			${w};` : ``}
		${minW ? `min-width:		${minW};` : ``}
		${maxW ? `max-width:		${maxW};` : ``}
		${h ? `height:			${h};` : ``}
		${minh ? `min-height:		${minh};` : ``}
		${maxh ? `max-height:		${maxh};` : ``}
		${background ? `background:		${background};` : ``}
		${position ? `position:		${position};` : ``}
		${overflow ? `overflow:		${overflow};` : ``}
		${radius ? `border-radius:		${radius};` : ``}
		${top ? `top:		${top};` : ``}
		${bottom ? `bottom:		${bottom};` : ``}
		${left ? `left:		${left};` : ``}
		${right ? `right:		${right};` : ``}
		${zIndex ? `z-index:		${zIndex};` : ``}
		${display ? `display:		${display};` : ``}
		${borderradius ? `border-radius:		${borderradius};` : ``}
		${transform ? `transform:		${transform};` : ``}
		${cursor ? `cursor:		${cursor};` : ``}
		${between ? `
			margin: 0 calc(-${between} * 2) 0 -${between};
			&>* {
				padding: 0 ${between};
			}
		` : ``}
		${justifycontent ? `justify-content:		${justifycontent};` : ``}
	`
}

export const FlexContainer = styled.div<StyledFlexPropsType>`
	display: flex;
	${({ queries, ...rest }: StyledFlexPropsType) => `
		${setStyle(rest)}
		${queries
			? Object.keys(queries).reverse()?.map((breakpoint: string) => {
				return `@media (max-width: ${breakpoint}px) {
							${setStyle(queries[breakpoint])}
						}`;
			}).join('')
			: ``
		}
	`}
	background-size: cover;
	background-position:center;
`

export const FlexChildContainer = styled.div<StyledFlexChildPropsType>`
	${({ w }) => w ? `width: ${w};` : ``}
	${({ h }) => h ? `height: ${h};` : ``}
`
